import { get } from 'lodash';
import { getOuterUrl } from '@wix/communities-blog-client-common';
import { getPostPageSectionUrl } from '../store/topology/topology-selectors';

import { SET_POSTS } from '../actions/set-posts';
import { FETCH_POST_SUCCESS } from '../actions/fetch-post';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS } from '../actions/fetch-tag-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../actions/fetch-feed-posts';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../post-page/actions/fetch-recent-posts';
import { FETCH_RELATED_POSTS_SUCCESS } from '../../post-page/actions/fetch-related-posts';
import { FETCH_SEARCH_SUCCESS } from '../../search/actions/fetch-search-types';
import { PUBLISH_POST_SUCCESS } from '../actions/publish-post-types';
import { POST_PUBLISHED } from '../actions/blog-socket';
import { FETCH_ARCHIVE_SUCCESS } from '../actions/fetch-archive';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../actions/fetch-hashtag-posts';

const POST_ACTIONS = [
  SET_POSTS,
  FETCH_POST_SUCCESS,
  FETCH_CATEGORY_POSTS_SUCCESS,
  FETCH_TAG_POSTS_SUCCESS,
  FETCH_FEED_POSTS_SUCCESS,
  FETCH_RECENT_POSTS_SUCCESS,
  FETCH_RELATED_POSTS_SUCCESS,
  FETCH_SEARCH_SUCCESS,
  PUBLISH_POST_SUCCESS,
  POST_PUBLISHED,
  FETCH_ARCHIVE_SUCCESS,
  FETCH_HASHTAG_POSTS_SUCCESS,
];

export const postLinkMiddleware = (store) => (next) => (action) => {
  if (POST_ACTIONS.includes(action.type)) {
    const posts = getPosts(action);
    const postPageSectionUrl = getPostPageSectionUrl(store.getState());

    posts.forEach((post) => {
      post.slug && (post.link = getOuterUrl(`/${post.slug}`, postPageSectionUrl));
    });
  }
  return next(action);
};

function getPosts({ type, payload } = {}) {
  switch (type) {
    case FETCH_SEARCH_SUCCESS:
      return get(payload, 'posts', []);
    case FETCH_POST_SUCCESS:
      const post = get(payload, 'post');
      return post ? [post] : [];
    default:
      return Array.isArray(payload) ? payload : [payload];
  }
}
