import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRequests from '../../common/services/create-requests';
import { wixDataReducer } from '@wix/communities-blog-client-common';
import commonReducers from '../../common/reducers';
import postCount from '../reducers/post-count';
import categories from '../../common/store/categories/categories-reducer';
import postStatsByDate from '../../common/store/post-stats-by-date/post-stats-by-date-reducer';
import { postLinkMiddleware } from '../../common/middleware/post-link-middleware';

export function createReduxStore({ appParams, wixCodeApi, compId, fedopsLogger, bundleName }) {
  const p = {};
  const getStore = () => p.store;

  const { request, platformizedRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
    postLinkMiddleware,
  ];

  const reducers = combineReducers({
    ...commonReducers,
    postStatsByDate,
    wixData: wixDataReducer,
    postCount,
    categories,
  });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
