import { createRequestWithBaseUrl, appendOriginInBackend } from '../controller/helpers';
import getEnvironment from './get-environment';

export default function createRequests({ appParams: { baseUrls }, wixCodeApi, bundleName, getStore }) {
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const { apiBaseUrlClient, apiPlatformizedBaseUrlClient } = baseUrls;
  const createRequest = createRequestWithBaseUrl({ wixCodeApi, getStore, bundleName });

  const blogApiBaseUrl = isEditor || isPreview ? apiBaseUrlClient : `${wixCodeApi.location.baseUrl}${apiBaseUrlClient}`;
  const request = createRequest(blogApiBaseUrl);

  const platformizedRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiPlatformizedBaseUrlClient });
  const platformizedRequest = createRequest(platformizedRequestUrl);

  const paywallRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: baseUrls.apiPaywallBaseUrl });
  const paywallRequest = createRequest(paywallRequestUrl);

  return {
    blogApiBaseUrl,
    request,
    platformizedRequest,
    paywallRequest,
  };
}
